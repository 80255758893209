import React from "react";
import "./styles.scss";

const Title: React.FC<SignupFormTitle & { descriptionAddon?: React.ReactElement }> = ({
	svg,
	heading,
	description,
	descriptionAddon
}) => {
	return (
		<div className="flex flex-col items-center overflow-hidden">
			<div className="relative inset-x-[-40px] w-[calc(100%_+_80px)] grid place-items-center self-stretch title-svg-gradient after:content-[''] after:absolute after:inset-0 before:content-[''] before:absolute before:inset-0">
				{svg}
			</div>
			<h4 className="text-lg text-gray-900 text-center font-semibold mb-1.5 mt-4">{heading}</h4>
			<p className="text-base text-gray-500 text-center whitespace-pre-line">
				{description}
				{descriptionAddon && (
					<>
						<br />
						{descriptionAddon}
					</>
				)}
			</p>
		</div>
	);
};

export default Title;

