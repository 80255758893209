import { X } from "@phosphor-icons/react";
import { Button, Tooltip } from "antd";
import clsx from "clsx";
import React, { ReactNode, useEffect } from "react";
import ReactDOM from "react-dom";
import { useEventListener } from "usehooks-ts";

const Overlay: React.FC<{ open: boolean; onClose: () => void; children: ReactNode; className?: string }> = ({
	open,
	onClose,
	children,
	className = " "
}) => {
	const overlayRef = React.useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (open) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}
	}, [open]);

	useEventListener("keydown", (e) => {
		if (e.key === "Escape") {
			onClose();
		}
	});

	if (!open) return null;

	return ReactDOM.createPortal(
		<div
			className={clsx(
				"fixed inset-0 z-[1000] grid max-h-screen place-items-center bg-white/50 bg-gradient-to-b from-black/10 to-black/50 px-20 py-10 backdrop-blur-sm",
				"overflow-auto transition",
				!open && "invisible select-none",
				className
			)}
			onClick={(e) => e.stopPropagation()}
			ref={overlayRef}>
			<div className="absolute right-6 top-4 flex flex-row-reverse items-stretch gap-6">
				<Tooltip trigger="hover" title="Close preview" mouseEnterDelay={1}>
					<Button type="text" style={{ padding: "0.75rem" }} data-color="gray" onClick={onClose}>
						<X size={32} weight="bold" />
					</Button>
				</Tooltip>
			</div>
			{children}
		</div>,
		document.body
	);
};

export default Overlay;

