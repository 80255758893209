import { ListBullets } from "@phosphor-icons/react";
import clsx from "clsx";
import React, { FormEventHandler } from "react";
import { useSearchParams } from "react-router-dom";
import "./styles.scss";

const HeaderTabs: React.FC<React.ComponentProps<"div">> = ({ className, ...props }) => {
	const [searchParams, setSearchParams] = useSearchParams(new URLSearchParams({ page: "questionnaire" }));

	const handleTabChange: FormEventHandler<HTMLInputElement> = (event) => {
		setSearchParams((prev) => {
			prev.set("page", (event.target as HTMLInputElement).value);

			return new URLSearchParams(prev);
		});
	};

	return (
		<div className={clsx("flex items-center justify-start", className)} {...props}>
			<div
				role="radiogroup"
				className="inline-flex items-center gap-2 p-1 rounded-lg bg-gray-50 border border-solid border-gray-100"
				onChange={handleTabChange}>
				<label
					className={
						"flex-1 flex items-center gap-2 px-3 py-2 rounded-md bg-inherit" +
						" text-sm font-medium text-gray-500 select-none cursor-pointer header-tab"
					}>
					<input
						type="radio"
						name="header-tab"
						value="questionnaire"
						hidden
						defaultChecked={searchParams.get("page") !== "documents"}
					/>
					<ListBullets size={16} color="currentColor" weight="bold" />
					Questionnaire
				</label>
				{/* <label
					className={
						"flex-1 flex items-center gap-2 px-3 py-2 rounded-md bg-inherit" +
						" text-sm font-medium text-gray-500 select-none cursor-pointer header-tab"
					}>
					<input
						type="radio"
						name="header-tab"
						value="documents"
						hidden
						disabled={isDocumentsTabDisabled}
						defaultChecked={searchParams.get("page") === "documents"}
					/>
					<FilePlus size={16} color="currentColor" weight="bold" />
					Documents
				</label> */}
			</div>
		</div>
	);
};

export default HeaderTabs;
