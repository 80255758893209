import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getCoTravelersByOrderId, getCompanyDetailsByOrderId, getOrderByOrderId, validateGuestUser } from "./Api";

const AppContext = createContext<AppContextValues | undefined>(undefined);

const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
	const [order, setOrder] = useState<Order>();
	const [companyDetails, setCompanyDetails] = useState<CompanyDetails>();
	const [isOrderLoading, setIsOrderLoading] = useState(false);
	const [isCompanyDetailsLoading, setIsCompanyDetailsLoading] = useState(false);
	const [isCoTravelersLoading, setIsCoTravelersLoading] = useState(false);
	const [coTravelers, setCoTravelers] = useState<Record<string, CoTraveler>>({});
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const orderId = window.location.pathname.split("/").at(2);

	const [applicationId, setApplicationId] = useState(searchParams.get("application_id") ?? "");

	useEffect(() => {
		setSearchParams({ application_id: applicationId })
	}, [applicationId, setSearchParams])

	const getOrder = useCallback(async (orderId: string) => {
		try {
			setIsOrderLoading(true);

			const { data } = await getOrderByOrderId(orderId);
			setOrder(data.data);
		} catch (error) {
			console.error(error);
		} finally {
			setIsOrderLoading(false);
		}
	}, []);

	const getCompanyDetails = async (orderId: string) => {
		try {
			setIsCompanyDetailsLoading(true);
			const { data } = await getCompanyDetailsByOrderId(orderId);
			setCompanyDetails(data.data);
		} catch (error) {
			console.error(error);
		} finally {
			setIsCompanyDetailsLoading(false);
		}
	};

	const getCoTravelers = async (orderId: string) => {
		let coTraveler: CoTraveler[] | undefined;
		try {
			setIsCoTravelersLoading(true);
			const {
				data: { data: coTravelerData }
			} = await getCoTravelersByOrderId(orderId);
			coTraveler = coTravelerData;
			setCoTravelers(
				coTravelerData.reduce((prev, current) => {
					return { ...prev, [current._id]: current };
				}, {} as Record<string, CoTraveler>)
			);

			if (!applicationId) {
				setApplicationId(coTravelerData[0].application_id);
			}
		} catch (error) {
		} finally {
			setIsCoTravelersLoading(false);
		}

		return coTraveler;
	};

	useEffect(() => {
		const onStorageChange = () => {
			const isSessionExpired = window.localStorage.getItem("session-expired") === "1";
			//'/questionnaire/:orderId'
			//[("", "questionnaire", ":orderId")];
			const isSignUp = window.location.pathname.split("/").at(3) === "signup";

			if (!isSignUp && isSessionExpired) {
				window.location.replace(window.location.pathname + "/signup");
				console.log("Session expired");
			}
		};

		window.addEventListener("storage", onStorageChange);

		return () => window.removeEventListener("storage", onStorageChange);
	}, []);

	useEffect(() => {
		if (!orderId) {
			navigate("/notfound");
			return;
		}

		getCompanyDetails(orderId);
		validateGuestUser(orderId).catch(() => {
			navigate(`/questionnaire/${orderId}/signup`);
		});
	}, [navigate, orderId]);

	return (
		<AppContext.Provider
			value={{
				order,
				coTravelers,
				applicationId,
				setApplicationId,
				companyDetails,
				isCompanyDetailsLoading,
				getOrder,
				isOrderLoading,
				getCompanyDetails,
				getCoTravelers,
				setCoTravelers
			}}>
			{children}
		</AppContext.Provider>
	);
};

export default AppProvider;

export const useApp = () => {
	const values = useContext(AppContext);

	if (values === undefined) {
		throw new Error("Cannot use useApp hook outside the AppProvider");
	}

	return values;
};

