import { Button, Form, Input, message } from "antd";
import { useApp } from "context/AppContext";
import { guestSendOtp } from "pages/SignUp/Api";
import { useEffect, useState } from "react";

const ContactStep: React.FC = () => {
	const { companyDetails } = useApp();
	const form = Form.useFormInstance<SignupFormValues>();
	const phone = Form.useWatch("phone", { form, preserve: true });
	const [isSendingOtp, setIsSendingOtp] = useState(false);

	useEffect(() => {
		const headingSpan = document.querySelectorAll(".heading-company-name");

		if (headingSpan.length > 0) {
			headingSpan.forEach((ele) => {
				ele.innerHTML = companyDetails?.organisation.name ?? "Company name";
			});
		}
	}, [companyDetails]);

	const handleSendOtp = async () => {
		try {
			setIsSendingOtp(true);

			const { data } = await guestSendOtp(phone);

			form.setFieldValue("session_id", data.data.session_id);
			form.setFieldValue("otp_digits", data.data.digits);
			form.setFieldValue("step", "otp");
			message.success(
				<span className="text-base text-gray-500">
					Code sent to <span className="font-medium text-primary-600">{phone}</span>
				</span>
			);
		} catch (err) {
			console.error(err);
		} finally {
			setIsSendingOtp(false);
		}
	};

	return (
		<div className="flex flex-col items-stretch gap-4 p-6">
			<div className="hidden md:block border-b border-solid border-gray-200 mb-2" />
			<Form.Item
				name="phone"
				label="Contact number"
				rules={[
					{
						len: 10,
						required: true,
						message: "Entered phone number is incorrect",
						validateTrigger: ["onBlur", "onChange"]
					}
				]}>
				<Input
					type="number"
					inputMode="numeric"
					onInput={(event) => {
						if (event.currentTarget.value.length > event.currentTarget.maxLength)
							event.currentTarget.value = event.currentTarget.value.slice(
								0,
								event.currentTarget.maxLength
							);
					}}
					prefix="+91"
					maxLength={10}
					placeholder="98765-43210"
				/>
			</Form.Item>
			<Form.Item shouldUpdate>
				{() => (
					<Button
						block
						htmlType="submit"
						type="primary"
						disabled={!form.isFieldTouched("phone") || form.getFieldError("phone").length > 0}
						loading={isSendingOtp}
						onClick={handleSendOtp}>
						Send OTP
					</Button>
				)}
			</Form.Item>
		</div>
	);
};

export default ContactStep;
