import { apiInstance } from "api";

export const generateQuestionnaire = (orderId: string, isSmvUser = false) =>
	apiInstance.get<ApiResponse<GenerateQuestionnaire>>(
		`/visa_orders/generate-questionnaire/${orderId}?showAll=${isSmvUser}`
	);

export const getAnswerStatus = (application_id: string) =>
	apiInstance.get<ApiResponse<GetAnswerStatusData>>(`/visa_answers/started/${application_id}`);

export const postAnswer = (
	order_id: string,
	application_id: string,
	data: PostAnswerData,
	onProgress?: ((event: UploadProgressEvent) => void) | undefined
) => {
	const form = new FormData();
	form.append("visa_questions_id", data.visa_questions_id);
	form.append("traveller_id", data.traveller_id);
	form.append("answer", data.answer);
	if (data.file) {
		form.append("fileData", data.file);
	}

	return apiInstance.post<ApiResponse<AnswerData>>(
		`/visa_answers/order/${order_id}/application/${application_id}`,
		form,
		{
			onUploadProgress: (event) => {
				onProgress?.({ percent: (event.loaded / (event.total ?? 100)) * 100 });
			}
		}
	);
};

