import { useEffect, useState } from "react";

const useDelayedLoading = (initialValue: boolean | undefined = false, delay: number | undefined = 1000) => {
	const [isLoading, setIsLoading] = useState(initialValue);
	const [isDelayedLoading, setIsDelayedLoading] = useState(initialValue);

	useEffect(() => {
		if (isLoading) {
			setIsDelayedLoading(isLoading);
		} else {
			setTimeout(() => {
				setIsDelayedLoading(isLoading);
			}, delay);
		}
	}, [isLoading, delay]);

	return [isDelayedLoading, setIsLoading] as const;
};

export default useDelayedLoading;

