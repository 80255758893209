import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 440 150" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g clipPath="url(#clip0_6403_104024)">
			<rect width={440} height={150} fill="white" />
			<mask
				id="mask0_6403_104024"
				style={{
					maskType: "alpha"
				}}
				maskUnits="userSpaceOnUse"
				x={42}
				y={-97}
				width={355}
				height={355}>
				<circle cx={219.5} cy={80.5} r={177} fill="url(#paint0_radial_6403_104024)" stroke="#E5E7EB" />
			</mask>
			<g mask="url(#mask0_6403_104024)">
				<g filter="url(#filter0_dd_6403_104024)">
					<circle cx={220} cy={81} r={48} fill="white" />
					<circle cx={220} cy={81} r={47.5} stroke="#E5E7EB" />
				</g>
				<circle cx={220} cy={81} r={69.5} stroke="#E5E7EB" />
				<circle cx={220} cy={81} r={91.5} stroke="#E5E7EB" />
				<circle cx={220} cy={81} r={116.5} stroke="#E5E7EB" />
				<circle cx={220} cy={81} r={144.5} stroke="#E5E7EB" />
				<circle cx={220} cy={81} r={174.5} stroke="#E5E7EB" />
				<circle cx={220} cy={81} r={201.5} stroke="#E5E7EB" />
			</g>
			<g filter="url(#filter1_f_6403_104024)">
				<ellipse cx={219.5} cy={-126.5} rx={220.5} ry={62.5} fill="#A5B4FC" />
			</g>
			<g filter="url(#filter2_ddi_6403_104024)">
				<rect x={150} y={32} width={140} height={303.147} rx={16} fill="#E0E7FF" />
				<g filter="url(#filter3_di_6403_104024)">
					<rect x={200} y={44} width={40} height={10} rx={5} fill="white" />
				</g>
				<rect
					x={150.5}
					y={32.5}
					width={139}
					height={302.147}
					rx={15.5}
					stroke="url(#paint1_linear_6403_104024)"
				/>
			</g>
			<rect y={90} width={440} height={60} fill="url(#paint2_linear_6403_104024)" />
			<g filter="url(#filter4_d_6403_104024)">
				<rect x={143} y={70.5} width={154} height={37} rx={8} fill="white" />
				<path
					d="M173.25 81H156.75C156.352 81 155.971 81.158 155.689 81.4393C155.408 81.7206 155.25 82.1022 155.25 82.5V97.5C155.248 97.786 155.329 98.0665 155.483 98.3076C155.637 98.5488 155.857 98.7404 156.117 98.8594C156.315 98.9517 156.531 98.9997 156.75 99C157.102 98.9992 157.443 98.8736 157.711 98.6456C157.715 98.6427 157.72 98.6392 157.723 98.6353L160.734 96H173.25C173.648 96 174.029 95.842 174.311 95.5607C174.592 95.2794 174.75 94.8978 174.75 94.5V82.5C174.75 82.1022 174.592 81.7206 174.311 81.4393C174.029 81.158 173.648 81 173.25 81ZM160.875 89.625C160.652 89.625 160.435 89.559 160.25 89.4354C160.065 89.3118 159.921 89.1361 159.836 88.9305C159.75 88.725 159.728 88.4988 159.772 88.2805C159.815 88.0623 159.922 87.8618 160.08 87.7045C160.237 87.5472 160.437 87.44 160.656 87.3966C160.874 87.3532 161.1 87.3755 161.306 87.4606C161.511 87.5458 161.687 87.69 161.81 87.875C161.934 88.06 162 88.2775 162 88.5C162 88.7984 161.881 89.0845 161.67 89.2955C161.46 89.5065 161.173 89.625 160.875 89.625ZM165 89.625C164.777 89.625 164.56 89.559 164.375 89.4354C164.19 89.3118 164.046 89.1361 163.961 88.9305C163.875 88.725 163.853 88.4988 163.897 88.2805C163.94 88.0623 164.047 87.8618 164.205 87.7045C164.362 87.5472 164.562 87.44 164.781 87.3966C164.999 87.3532 165.225 87.3755 165.431 87.4606C165.636 87.5458 165.812 87.69 165.935 87.875C166.059 88.06 166.125 88.2775 166.125 88.5C166.125 88.7984 166.006 89.0845 165.795 89.2955C165.585 89.5065 165.298 89.625 165 89.625ZM169.125 89.625C168.902 89.625 168.685 89.559 168.5 89.4354C168.315 89.3118 168.171 89.1361 168.086 88.9305C168 88.725 167.978 88.4988 168.022 88.2805C168.065 88.0623 168.172 87.8618 168.33 87.7045C168.487 87.5472 168.687 87.44 168.906 87.3966C169.124 87.3532 169.35 87.3755 169.556 87.4606C169.761 87.5458 169.937 87.69 170.06 87.875C170.184 88.06 170.25 88.2775 170.25 88.5C170.25 88.7984 170.131 89.0845 169.92 89.2955C169.71 89.5065 169.423 89.625 169.125 89.625Z"
					fill="#6366F1"
				/>
				<rect x={185} y={79.5} width={32} height={8} rx={3} fill="#E0E7FF" />
				<rect x={185} y={91.5} width={70} height={6} rx={2} fill="#EEF2FF" />
				<rect x={143.5} y={71} width={153} height={36} rx={7.5} stroke="url(#paint3_linear_6403_104024)" />
			</g>
		</g>
		<defs>
			<filter
				id="filter0_dd_6403_104024"
				x={167}
				y={32}
				width={106}
				height={108}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB">
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feMorphology radius={1} operator="erode" in="SourceAlpha" result="effect1_dropShadow_6403_104024" />
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={2} />
				<feColorMatrix type="matrix" values="0 0 0 0 0.184314 0 0 0 0 0.627451 0 0 0 0 0.968627 0 0 0 0.04 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6403_104024" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feMorphology radius={1} operator="erode" in="SourceAlpha" result="effect2_dropShadow_6403_104024" />
				<feOffset dy={6} />
				<feGaussianBlur stdDeviation={3} />
				<feColorMatrix type="matrix" values="0 0 0 0 0.184314 0 0 0 0 0.631373 0 0 0 0 0.968627 0 0 0 0.06 0" />
				<feBlend mode="normal" in2="effect1_dropShadow_6403_104024" result="effect2_dropShadow_6403_104024" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_6403_104024" result="shape" />
			</filter>
			<filter
				id="filter1_f_6403_104024"
				x={-151}
				y={-339}
				width={741}
				height={425}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB">
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
				<feGaussianBlur stdDeviation={75} result="effect1_foregroundBlur_6403_104024" />
			</filter>
			<filter
				id="filter2_ddi_6403_104024"
				x={128}
				y={30}
				width={184}
				height={347.146}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB">
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feMorphology radius={3} operator="erode" in="SourceAlpha" result="effect1_dropShadow_6403_104024" />
				<feOffset dy={10} />
				<feGaussianBlur stdDeviation={5} />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6403_104024" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feMorphology radius={3} operator="erode" in="SourceAlpha" result="effect2_dropShadow_6403_104024" />
				<feOffset dy={20} />
				<feGaussianBlur stdDeviation={12.5} />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
				<feBlend mode="normal" in2="effect1_dropShadow_6403_104024" result="effect2_dropShadow_6403_104024" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_6403_104024" result="shape" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feMorphology radius={2.5} operator="erode" in="SourceAlpha" result="effect3_innerShadow_6403_104024" />
				<feOffset />
				<feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
				<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
				<feBlend mode="normal" in2="shape" result="effect3_innerShadow_6403_104024" />
			</filter>
			<filter
				id="filter3_di_6403_104024"
				x={199.5}
				y={44}
				width={41}
				height={11.5}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB">
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={1} />
				<feGaussianBlur stdDeviation={0.25} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6403_104024" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6403_104024" result="shape" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={1} />
				<feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
				<feColorMatrix type="matrix" values="0 0 0 0 0.8 0 0 0 0 0.845161 0 0 0 0 1 0 0 0 1 0" />
				<feBlend mode="normal" in2="shape" result="effect2_innerShadow_6403_104024" />
			</filter>
			<filter
				id="filter4_d_6403_104024"
				x={141}
				y={69.5}
				width={158}
				height={41}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB">
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={1} />
				<feGaussianBlur stdDeviation={1} />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6403_104024" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6403_104024" result="shape" />
			</filter>
			<radialGradient
				id="paint0_radial_6403_104024"
				cx={0}
				cy={0}
				r={1}
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(219.5 80.5) rotate(90) scale(158.5 188.958)">
				<stop stopColor="#D9D9D9" />
				<stop offset={0.946204} stopColor="#D9D9D9" stopOpacity={0} />
			</radialGradient>
			<linearGradient
				id="paint1_linear_6403_104024"
				x1={220}
				y1={32}
				x2={220}
				y2={335.147}
				gradientUnits="userSpaceOnUse">
				<stop stopColor="#E0E7FF" />
				<stop offset={1} stopColor="#F3F4F6" stopOpacity={0} />
			</linearGradient>
			<linearGradient
				id="paint2_linear_6403_104024"
				x1={220}
				y1={90}
				x2={220}
				y2={150}
				gradientUnits="userSpaceOnUse">
				<stop stopColor="white" stopOpacity={0} />
				<stop offset={1} stopColor="white" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_6403_104024"
				x1={220}
				y1={70.5}
				x2={220}
				y2={107.5}
				gradientUnits="userSpaceOnUse">
				<stop stopColor="#C9D5FC" />
				<stop offset={1} stopColor="#EBF2FF" stopOpacity={0} />
			</linearGradient>
			<clipPath id="clip0_6403_104024">
				<rect width={440} height={150} fill="white" />
			</clipPath>
		</defs>
	</svg>
);
export default SvgComponent;

