import { apiInstance } from "api";

export const getOrderByOrderId = (orderId: string) =>
	apiInstance.get<ApiResponse<Order>>(`/visa_orders/${orderId}`);

export const getCompanyDetailsByOrderId = (orderId: string) =>
	apiInstance.get<ApiResponse<CompanyDetails>>(`/visa_orders/partial/${orderId}`);

export const getCoTravelersByOrderId = (orderId: string) =>
	apiInstance.get<ApiResponse<CoTraveler[]>>(`/visa_orders/${orderId}/co-traveller`);

export const validateGuestUser = (orderId: string) =>
	apiInstance.get<ApiResponse<Boolean>>(`/users/validate-guest-user/${orderId}`);
