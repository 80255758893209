import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 440 150" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g clipPath="url(#clip0_6403_104070)">
			<rect width={440} height={150} fill="white" />
			<mask
				id="mask0_6403_104070"
				style={{
					maskType: "alpha"
				}}
				maskUnits="userSpaceOnUse"
				x={42}
				y={-97}
				width={355}
				height={355}>
				<circle cx={219.5} cy={80.5} r={177} fill="url(#paint0_radial_6403_104070)" stroke="#E5E7EB" />
			</mask>
			<g mask="url(#mask0_6403_104070)">
				<g filter="url(#filter0_dd_6403_104070)">
					<circle cx={220} cy={81} r={48} fill="white" />
					<circle cx={220} cy={81} r={47.5} stroke="#E5E7EB" />
				</g>
				<circle cx={220} cy={81} r={69.5} stroke="#E5E7EB" />
				<circle cx={220} cy={81} r={91.5} stroke="#E5E7EB" />
				<circle cx={220} cy={81} r={116.5} stroke="#E5E7EB" />
				<circle cx={220} cy={81} r={144.5} stroke="#E5E7EB" />
				<circle cx={220} cy={81} r={174.5} stroke="#E5E7EB" />
				<circle cx={220} cy={81} r={201.5} stroke="#E5E7EB" />
			</g>
			<g filter="url(#filter1_f_6403_104070)">
				<ellipse cx={219.5} cy={-126.5} rx={220.5} ry={62.5} fill="#A5B4FC" />
			</g>
			<g filter="url(#filter2_ddi_6403_104070)">
				<rect x={150} y={32} width={140} height={303.147} rx={16} fill="#E0E7FF" />
				<g filter="url(#filter3_di_6403_104070)">
					<rect x={200} y={44} width={40} height={10} rx={5} fill="white" />
				</g>
				<rect
					x={150.5}
					y={32.5}
					width={139}
					height={302.147}
					rx={15.5}
					stroke="url(#paint1_linear_6403_104070)"
				/>
			</g>
			<rect y={90} width={440} height={60} fill="url(#paint2_linear_6403_104070)" />
			<g filter="url(#filter4_d_6403_104070)">
				<rect x={143} y={70.5} width={154} height={37} rx={8} fill="white" />
				<path
					d="M174.739 92.9136C174.572 94.1839 173.948 95.35 172.984 96.1939C172.02 97.0379 170.781 97.5021 169.5 97.4998C162.056 97.4998 156 91.4436 156 83.9998C155.998 82.7185 156.462 81.4802 157.306 80.5161C158.15 79.5521 159.316 78.9282 160.586 78.7611C160.907 78.7218 161.233 78.7876 161.514 78.9484C161.794 79.1093 162.016 79.3566 162.144 79.6536L164.124 84.0739C164.124 84.0776 164.124 84.0814 164.124 84.0851C164.223 84.3124 164.264 84.5606 164.243 84.8074C164.222 85.0543 164.14 85.2922 164.005 85.4998C163.988 85.5251 163.971 85.5486 163.952 85.572L162 87.8857C162.702 89.3126 164.195 90.792 165.64 91.4961L167.922 89.5545C167.945 89.5357 167.968 89.5181 167.993 89.502C168.2 89.3635 168.439 89.2789 168.687 89.256C168.936 89.2331 169.186 89.2725 169.416 89.3707L169.427 89.3764L173.843 91.3554C174.14 91.4835 174.388 91.7045 174.55 91.9854C174.711 92.2663 174.778 92.5919 174.739 92.9136ZM167.25 83.9998H169.5V86.2498C169.5 86.4487 169.579 86.6395 169.72 86.7801C169.86 86.9208 170.051 86.9998 170.25 86.9998C170.449 86.9998 170.64 86.9208 170.78 86.7801C170.921 86.6395 171 86.4487 171 86.2498V83.9998H173.25C173.449 83.9998 173.64 83.9208 173.78 83.7801C173.921 83.6395 174 83.4487 174 83.2498C174 83.0509 173.921 82.8601 173.78 82.7195C173.64 82.5788 173.449 82.4998 173.25 82.4998H171V80.2498C171 80.0509 170.921 79.8601 170.78 79.7195C170.64 79.5788 170.449 79.4998 170.25 79.4998C170.051 79.4998 169.86 79.5788 169.72 79.7195C169.579 79.8601 169.5 80.0509 169.5 80.2498V82.4998H167.25C167.051 82.4998 166.86 82.5788 166.72 82.7195C166.579 82.8601 166.5 83.0509 166.5 83.2498C166.5 83.4487 166.579 83.6395 166.72 83.7801C166.86 83.9208 167.051 83.9998 167.25 83.9998Z"
					fill="#6366F1"
				/>
				<text
					fill="#6B7280"
					xmlSpace="preserve"
					style={{
						whiteSpace: "pre"
					}}
					fontSize={10}
					fontWeight={500}
					letterSpacing="0em">
					<tspan x={185.071} y={92.1364}>
						{"+91 98765-43XXX"}
					</tspan>
				</text>
				<rect x={143.5} y={71} width={153} height={36} rx={7.5} stroke="url(#paint3_linear_6403_104070)" />
			</g>
		</g>
		<defs>
			<filter
				id="filter0_dd_6403_104070"
				x={167}
				y={32}
				width={106}
				height={108}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB">
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feMorphology radius={1} operator="erode" in="SourceAlpha" result="effect1_dropShadow_6403_104070" />
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={2} />
				<feColorMatrix type="matrix" values="0 0 0 0 0.184314 0 0 0 0 0.627451 0 0 0 0 0.968627 0 0 0 0.04 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6403_104070" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feMorphology radius={1} operator="erode" in="SourceAlpha" result="effect2_dropShadow_6403_104070" />
				<feOffset dy={6} />
				<feGaussianBlur stdDeviation={3} />
				<feColorMatrix type="matrix" values="0 0 0 0 0.184314 0 0 0 0 0.631373 0 0 0 0 0.968627 0 0 0 0.06 0" />
				<feBlend mode="normal" in2="effect1_dropShadow_6403_104070" result="effect2_dropShadow_6403_104070" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_6403_104070" result="shape" />
			</filter>
			<filter
				id="filter1_f_6403_104070"
				x={-151}
				y={-339}
				width={741}
				height={425}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB">
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
				<feGaussianBlur stdDeviation={75} result="effect1_foregroundBlur_6403_104070" />
			</filter>
			<filter
				id="filter2_ddi_6403_104070"
				x={128}
				y={30}
				width={184}
				height={347.146}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB">
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feMorphology radius={3} operator="erode" in="SourceAlpha" result="effect1_dropShadow_6403_104070" />
				<feOffset dy={10} />
				<feGaussianBlur stdDeviation={5} />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6403_104070" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feMorphology radius={3} operator="erode" in="SourceAlpha" result="effect2_dropShadow_6403_104070" />
				<feOffset dy={20} />
				<feGaussianBlur stdDeviation={12.5} />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
				<feBlend mode="normal" in2="effect1_dropShadow_6403_104070" result="effect2_dropShadow_6403_104070" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_6403_104070" result="shape" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feMorphology radius={2.5} operator="erode" in="SourceAlpha" result="effect3_innerShadow_6403_104070" />
				<feOffset />
				<feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
				<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
				<feBlend mode="normal" in2="shape" result="effect3_innerShadow_6403_104070" />
			</filter>
			<filter
				id="filter3_di_6403_104070"
				x={199.5}
				y={44}
				width={41}
				height={11.5}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB">
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={1} />
				<feGaussianBlur stdDeviation={0.25} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6403_104070" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6403_104070" result="shape" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={1} />
				<feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
				<feColorMatrix type="matrix" values="0 0 0 0 0.8 0 0 0 0 0.845161 0 0 0 0 1 0 0 0 1 0" />
				<feBlend mode="normal" in2="shape" result="effect2_innerShadow_6403_104070" />
			</filter>
			<filter
				id="filter4_d_6403_104070"
				x={141}
				y={69.5}
				width={158}
				height={41}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB">
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={1} />
				<feGaussianBlur stdDeviation={1} />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6403_104070" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6403_104070" result="shape" />
			</filter>
			<radialGradient
				id="paint0_radial_6403_104070"
				cx={0}
				cy={0}
				r={1}
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(219.5 80.5) rotate(90) scale(158.5 188.958)">
				<stop stopColor="#D9D9D9" />
				<stop offset={0.946204} stopColor="#D9D9D9" stopOpacity={0} />
			</radialGradient>
			<linearGradient
				id="paint1_linear_6403_104070"
				x1={220}
				y1={32}
				x2={220}
				y2={335.147}
				gradientUnits="userSpaceOnUse">
				<stop stopColor="#E0E7FF" />
				<stop offset={1} stopColor="#F3F4F6" stopOpacity={0} />
			</linearGradient>
			<linearGradient
				id="paint2_linear_6403_104070"
				x1={220}
				y1={90}
				x2={220}
				y2={150}
				gradientUnits="userSpaceOnUse">
				<stop stopColor="white" stopOpacity={0} />
				<stop offset={1} stopColor="white" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_6403_104070"
				x1={220}
				y1={70.5}
				x2={220}
				y2={107.5}
				gradientUnits="userSpaceOnUse">
				<stop stopColor="#C9D5FC" />
				<stop offset={1} stopColor="#EBF2FF" stopOpacity={0} />
			</linearGradient>
			<clipPath id="clip0_6403_104070">
				<rect width={440} height={150} fill="white" />
			</clipPath>
		</defs>
	</svg>
);
export default SvgComponent;

