import { CalendarBlank } from "@phosphor-icons/react";
import logo from "asset/logo.svg";
import clsx from "clsx";

import { useApp } from "context/AppContext";
import dayjs from "dayjs";
import { Flags } from "flagpack-core";
import { useQuestionnaire } from "pages/ShareQuestionnaire";
import { useRef } from "react";
import { useOnClickOutside } from "usehooks-ts";
import "./styles.scss"
import { Flag } from "x-wings";

const Sidebar: React.FC = () => {
	const { order, companyDetails, coTravelers, applicationId } = useApp();
	const { category, isSidebarOpen, setIsSidebarOpen } = useQuestionnaire();
	const traveler = Object.values(coTravelers).find((t) => t.application_id === applicationId);
	const ref = useRef<HTMLElement>(null);

	useOnClickOutside(ref, (event) => {
		event.stopPropagation();
		setIsSidebarOpen(false);
	});

	return (
		<aside
			ref={ref}
			className={clsx(
				"row-start-1 col-start-1 row-end-[-1] col-end-2 h-screen",
				"absolute md:sticky md:top-0 md:left-0 md:bottom-0 border-r border-solid border-gray-200",
				"md:!flex flex-col items-stretch bg-white",
				isSidebarOpen ? "absolute top-0 left-0 bottom-0 z-20 w-[320px] flex" : "hidden"
			)}>
			<div className="flex items-center justify-center px-6 gap-4 mt-8 mb-12 h-20">
				<img
					className="hidden md:!block max-w-full w-auto h-full mix-blend-multiply"
					height={48}
					width={48}
					src={companyDetails?.organisation.agency_logo ?? logo}
					alt="logo"
				/>
				{order && (
					<div className="flex md:!hidden items-center flex-wrap gap-3 w-full">
						<Flag code={order.country.symbol as Flags} size="l" />
						<span className="text-lg text-gray-800 font-semibold">{order.country.name}</span>
						<span className="text-3xl font-medium text-gray-300">·</span>
						<span className="flex items-center gap-1.5 text-lg text-gray-800 font-semibold">{`${traveler?.first_name} ${traveler?.last_name}`}</span>
						<span className="text-3xl font-medium text-gray-300">·</span>
						<span className="flex items-center gap-1.5 text-base text-gray-500 font-medium">
							<CalendarBlank size={16} color="#667085" weight="bold" />
							{dayjs(order.travel_start_date).format("D MMM")} -
							{dayjs(order.travel_end_date).format(" D MMM")}
						</span>
					</div>
				)}
			</div>
			<ul className="flex flex-col gap-4 pl-8">
				{Object.entries(category).map(([str, category]) => (
					<li key={str} className="flex items-center text-sm font-medium text-gray-500" data-category={str}>
						<div
							className={clsx(
								"inline-block rounded-full h-1 w-1 mr-2.5 bg-current ",
								"outline outline-offset-2 outline-current text-gray-400",
								category.completedCount === category.totalCount && "text-emerald-600",
								category.rejectedCount > 0 && "text-rose-600"
							)}
						/>
						{str}
					</li>
				))}
			</ul>

			{/* <ul className="mt-auto flex flex-col gap-4 pl-8 text-sm font-medium text-gray-500 pb-8">
				<li className="flex items-center">
					<div
						className={clsx(
							"inline-block rounded-full h-1 w-1 mr-2.5 bg-current ",
							"outline outline-offset-2 outline-current text-emerald-600"
						)}
					/>
					Answered
				</li>
				<li className="flex items-center">
					<div
						className={clsx(
							"inline-block rounded-full h-1 w-1 mr-2.5 bg-current ",
							"outline outline-offset-2 outline-current text-rose-600"
						)}
					/>
					Errored
				</li>
			</ul> */}
		</aside>
	);
};

export default Sidebar;

