import axios from "axios";
import { API_BASE_URL } from "../env";
import { setupRequestInterceptors, setupResponseInterceptors } from "./setupInterceptors";

const apiInstance = axios.create({
	baseURL: `${API_BASE_URL}/v1`,
	withCredentials: true
});

setupRequestInterceptors(apiInstance);

setupResponseInterceptors(
	apiInstance,
	(res) => {
		const status = res.status;
		const url = res.config.url;
		if (status === 200 && url === "/users/current") {
			localStorage.setItem("session-expired", "0");
		}
	},
	(error) => {
		const status = error?.response?.status || 0;
		if (status === 401) {
			localStorage.setItem("session-expired", "1");
		}
	}
);

export { apiInstance,  };

