import React from "react";
import Header from "./Components/Header";
import SignupForm from "./Components/SignupFormSection";

const ShareQuestionnaire: React.FC = () => {
	return (
		<main className="grid min-h-screen bg-gray-50">
			<Header />
			<div className="absolute row-start-1 col-start-1 row-end-[-1] col-end-[-1] h-full w-full">
				<SignupForm />
			</div>
		</main>
	);
};

export default ShareQuestionnaire;

