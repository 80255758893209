import { UserPlus, X } from "@phosphor-icons/react";
import { Button, Form, Input, Modal } from "antd";
import axios from "axios";
import { useApp } from "context/AppContext";
import { claimApplicationByApplicationId, searchPassportByOrderId } from "pages/SignUp/Api";
import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./styles.scss";

type AddCoTravellerModalProps = {
	isOpen: boolean;
	onClose: () => void;
};

const AddCoTravellerModal: React.FC<AddCoTravellerModalProps> = ({ isOpen, onClose }) => {
	const { setCoTravelers, coTravelers } = useApp();
	const [form] = Form.useForm<AddCoTravelerFormValues>();
	const [passportData, setPassportData] = useState<SearchPassportByOrderIdData>();
	const [isAdding, setIsAdding] = useState(false);
	const [isSearching, setIsSearching] = useState(false);
	const { orderId } = useParams();
	const navigate = useNavigate();
	const searchCancelToken = useRef<AbortController>();

	const handleClose = () => {
		form.resetFields();
		onClose();
	};

	const handleSearchPassport = async (passport: string) => {
		if (!orderId) {
			navigate("/notfound");
			return;
		}
		let rejected = false;
		searchCancelToken.current?.abort();
		searchCancelToken.current = new AbortController();
		try {
			setIsSearching(true);
			const { data } = await searchPassportByOrderId(orderId, passport, searchCancelToken.current.signal);
			setPassportData(data.data);
			form.setFieldValue("name", `${data.data.first_name} ${data.data.last_name ?? ""}`);
		} catch (error) {
			if (!axios.isCancel(error)) return;
			form.setFieldValue("name", "");
			rejected = true;
		} finally {
			setIsSearching(false);
		}

		return !rejected;
	};

	const handleAddCoTraveler = async () => {
		if (!passportData) return;
		if (!orderId) {
			navigate("/notfound");
			return;
		}
		try {
			setIsAdding(true);
			const { data } = await claimApplicationByApplicationId(passportData.application_id, orderId);
			setCoTravelers((prev) => ({
				...prev,
				[passportData.application_id]: {
					_id: passportData._id,
					application_id: passportData.application_id,
					first_name: passportData.first_name,
					last_name: passportData.last_name,
					passport: passportData.passport,
					questionCount: 1,
					email: "",
					groupAnswers: undefined,
				}
			}));
			handleClose();
		} catch (error) {
			console.error(error);
		} finally {
			setIsAdding(false);
		}
	};

	return (
		<Modal
			width={400}
			open={isOpen}
			onCancel={handleClose}
			destroyOnClose
			centered
			closeIcon={false}
			footer={false}
			styles={{
				mask: {
					backgroundColor: "unset",
					backgroundImage: "linear-gradient(180deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.50) 100%)"
				}
			}}
			modalRender={(node) => (
				<Form className="add-co-traveler-modal-form" form={form} layout="vertical">
					{node}
				</Form>
			)}>
			<div className="flex items-center gap-2.5 bg-gray-50 border-b border-solid border-gray-100 pt-3 pb-2 px-4">
				<UserPlus
					className="p-1 bg-white rounded-md ring-1 ring-gray-300 shadow-md"
					size={24}
					color="#4F46E5"
					weight="bold"
				/>
				<h3 className="text-base text-gray-900 font-medium">Add co-traveller</h3>

				<Button className="ml-auto" type="text" onClick={handleClose} style={{ padding: "0.5rem" }}>
					<X size={16} color="#9CA3AF" weight="bold" />
				</Button>
			</div>
			<div className="flex flex-col gap-4 py-6 px-4">
				<Form.Item
					name="passport"
					label="Passport number"
					extra="Find this on the first page of your passport."
					rules={[
						{
							validator: async (_, value) => {
								const validatePassport = async (value: any) => {
									// Required check
									if (!value) {
										return "Passport number is required!";
									}

									// Pattern check
									if (!/^[A-Z]\d{7}$/.test(value)) {
										return "Please enter a valid passport number!";
									}

									// Co-traveller check
									if (Object.values(coTravelers).find(t => t.passport === value)) {
										form.setFieldValue("name", "");
										return "Co-traveller already exists";
									}

									// Traveller in order check
									const validationResult = await handleSearchPassport(value);
									if (!validationResult) {
										return "Traveller does not exist in order";
									}

									// All validations passed
									return null;
								};
								const errorMessage = await validatePassport(value);
								return errorMessage ? Promise.reject(errorMessage) : Promise.resolve();
							}
						}
					]}>
					<Input placeholder="S6044075" />
				</Form.Item>
				<Form.Item name="name" label="Name of traveller (as per Passport)" required>
					<Input placeholder="John Doe" disabled />
				</Form.Item>
			</div>
			<div className="flex justify-stretch items-start gap-3 px-4 pb-4 pt-3 border-t border-solid border-gray-100">
				<Button type="default" onClick={handleClose} block>
					Cancel
				</Button>
				<Button
					type="primary"
					htmlType="submit"
					block
					loading={isAdding}
					onClick={handleAddCoTraveler}
					disabled={
						isSearching ||
						!form.isFieldTouched("passport") ||
						form.getFieldsError().filter(({ errors }) => errors.length).length > 0
					}>
					Add
				</Button>
			</div>
		</Modal>
	);
};

export default AddCoTravellerModal;

