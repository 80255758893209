import { FilePlus, IconProps, ListBullets } from "@phosphor-icons/react";
import { Button, Checkbox, Divider, Form } from "antd";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import colors from "tailwindcss/colors";
import { Pill } from "x-wings";

const QuestionnaireSubmit: React.FC = () => {
	const [searchParams] = useSearchParams();
	const isDocumentPage = searchParams.get("page") === "documents";
	const [isTNCAgreed, setIsTNCAgreed] = useState(false);
	const form = Form.useFormInstance();
	const isButtonDisabled = Form.useWatch("disable-submit-continue-button", { form, preserve: true });

	const iconProps: IconProps = {
		size: 40,
		color: colors.indigo["600"],
		className: "p-2 rounded-lg bg-white ring-1 ring-gray-900/10 shadow"
	};
	return (
		<div className="relative p-4 rounded-xl bg-gray-50 ring-1 ring-gray-900/10 shadow-xl mt-auto">
			<Pill className="absolute top-4 right-4 bg-white" color="red" text="IMPORTANT" />
			{isDocumentPage ? <FilePlus {...iconProps} /> : <ListBullets {...iconProps} />}
			<p className="text-base text-gray-700 mt-5">
				We highly recommend taking the time to double-check that all the information is correct & complete to
				avoid any delays or issues during the review process.
			</p>
			<div className="h-5 border-l border-l-solid border-l-primary-200 ml-1.5 mt-2 mb-2" />
			<Checkbox className="" checked={isTNCAgreed} onChange={(e) => setIsTNCAgreed(e.target.checked)}>
				{isDocumentPage
					? "I have read & reviewed the documents myself"
					: "I have read & reviewed the questionnaire myself"}
			</Checkbox>
			<Divider className="border-gray-200 my-5" />
			<Button
				type="primary"
				size="large"
				disabled={!isTNCAgreed || isButtonDisabled}
				onClick={() => window.location.reload()}>
				Submit & continue
			</Button>
		</div>
	);
};

export default QuestionnaireSubmit;


