import { Form } from "antd";
import ContactStepSvg from "./Components/SignupFormSection/Components/ContactStep/Components/Svg";
import OtpStepSvg from "./Components/SignupFormSection/Components/OtpStep/Components/Svg";
import PassportStep from "./Components/SignupFormSection/Components/PassportStep/Components/Svg";

export const TITLE: Record<SignupFormValues["step"], SignupFormTitle> = {
	phone: {
		svg: <ContactStepSvg />,
		heading: (
			<>
				Welcome to <span className="text-primary-600 heading-company-name">Company Name</span>
			</>
		),
		description: "Verify your mobile number to continue\nwith your visa application"
	},
	otp: {
		svg: <OtpStepSvg />,
		heading: (
			<>
				Please check your <span className="text-primary-600">messages</span>
			</>
		),
		description: "We've sent a verification code to"
	},
	passport: {
		svg: <PassportStep />,
		heading: (
			<>
				Please enter your <span className="text-primary-600">passport number</span>
			</>
		),
		description: "You can find you 8-digit passport number\non the top right corner of the front page"
	}
};

