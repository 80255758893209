import { Spin } from "antd";
import AppProvider, { useApp } from "context/AppContext";
import ShareQuestionnaire from "pages/ShareQuestionnaire";
import SignUp from "pages/SignUp";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "x-wings";


function GlobalLoaderWrapper ({children}: {children: React.ReactNode}) {

	const { isCompanyDetailsLoading } = useApp();

	if(isCompanyDetailsLoading) return (
		<div className="h-screen w-screen grid place-items-center">
			<Spin size="large" />
		</div>
	)

	else return <>{children}</>;


}

function App() {
	return (
		<BrowserRouter>
			<AppProvider>
				<ThemeProvider>
					<GlobalLoaderWrapper>
					<Routes>
						<Route path="/questionnaire">
							<Route path=":orderId/signup" element={<SignUp />} />
							<Route path=":orderId" element={<ShareQuestionnaire />} />
						</Route>
						<Route path="/notfound" element={"Page not found! Contact travel agent"} />
					</Routes>
					</GlobalLoaderWrapper>
				</ThemeProvider>
			</AppProvider>
		</BrowserRouter>
	);
}

export default App;
