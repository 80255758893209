import { apiInstance } from "api";

export const guestLoginForOrderId = (phone: string | number, order_id: string, otp: string, session_id: string) =>
	apiInstance.post<ApiResponse<GuestLogin>>("/guest/login", {
		phone,
		verify: {
			session_id,
			otp
		},
		order_id: order_id
	});

/**
 * @param phone "without country code eg. +91, +1..."
 */
export const guestSendOtp = (phone: string | number) =>
	apiInstance.post<ApiResponse<GuestSendOtp>>(
		"/guest/send-otp",
		{ phone, purpose: "login" },
		{
			transformResponse: (json: string) => {
				const data = JSON.parse(json) as ApiResponse<GuestSendOtp>;

				localStorage.setItem("session_id", data.data.session_id);
				return data;
			}
		}
	);

export const searchPassportByOrderId = (orderId: string, passport: string, signal?: AbortController["signal"]) =>
	apiInstance.get<ApiResponse<SearchPassportByOrderIdData>>(`/visa_orders/${orderId}/search-passport`, {
		params: { passport },
		signal
	});

export const claimApplicationByApplicationId = (applicationId: string, orderId: string) =>
	apiInstance.patch<ApiResponse<undefined>>(`visa_orders/${applicationId}/claim`, { order_id: orderId });

