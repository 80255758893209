import { Form } from "antd";
import ContactStep from "./Components/ContactStep";
import OtpStep from "./Components/OtpStep";
import PassportStep from "./Components/PassportStep";
import Title from "./Components/Title";
import { TITLE } from "../../constants";

const SignupForm: React.FC = () => {
	const [form] = Form.useForm<SignupFormValues>();
	const step = Form.useWatch("step", form);
	const phone = Form.useWatch("phone", { form, preserve: true });

	return (
		<Form
			className="relative h-full w-full flex justify-center items-center"
			form={form}
			preserve
			layout="vertical"
			initialValues={{ step: "phone" }}>
			<Form.Item name="step" className="hidden">
				<div role="radiogroup">
					<input hidden type="radio" name="step" value="phone" defaultChecked />
					<input hidden type="radio" name="step" value="otp" />
					<input hidden type="radio" name="step" value="passport" />
				</div>
			</Form.Item>
			<div className="grid place-content-center grid-cols-1 gap-5 md:gap-10 self-end md:self-center">
				<h3 className=" text-lg md:text-2xl text-gray-900 font-semibold text-center">
					Your travel agent is inviting you to <span className="text-primary-700">collaborate</span> on your
					travel questionnaire!
					<p className="hidden md:block text-lg text-gray-700 font-normal mt-1">
						Please login with your registered contact number to access your questionnaire
					</p>
				</h3>
				<div className="self-center block md:hidden">
					{step && (
						<Title
							svg={TITLE[step].svg}
							heading={TITLE[step].heading}
							description={TITLE[step].description}
							descriptionAddon={
								step === "otp" ? <span className="text-gray-800">+91 {phone}</span> : undefined
							}
						/>
					)}
				</div>
				<section
					className={
						"self-end md:self-center max-w-md flex-1 mx-auto overflow-hidden w-full bg-white shadow-md ring-1 ring-gray-200 rounded-xl mb-8"
					}>
					{step && (
						<>
							<div className="hidden md:block">
								<Title
									svg={TITLE[step].svg}
									heading={TITLE[step].heading}
									description={TITLE[step].description}
									descriptionAddon={
										step === "otp" ? <span className="text-gray-800">+91 {phone}</span> : undefined
									}
								/>
							</div>
							{(() => {
								switch (step) {
									case "phone":
										return <ContactStep />;
									case "otp":
										return <OtpStep />;
									case "passport":
										return <PassportStep />;
									default:
										return <ContactStep />;
								}
							})()}
						</>
					)}
				</section>
			</div>
		</Form>
	);
};

export default SignupForm;

