import { useLayoutEffect, useRef } from "react";
import { useDebounce, useIntersectionObserver } from "usehooks-ts";

const useActiveQuestionCount = ({ dependencies }: { dependencies: unknown[] }) => {
	const ref = useRef(null);
	const entry = useIntersectionObserver(ref, { threshold: 0.2 });
	const debouncedEntry = useDebounce(entry);

	useLayoutEffect(() => {
		if (!debouncedEntry) return;
		const active = debouncedEntry?.isIntersecting;
		const questionId = debouncedEntry?.target.getAttribute("data-question-id");
		const category = debouncedEntry?.target.getAttribute("data-category");
		if (!questionId || !category) return;

		const countBoxElement = document.querySelector(`span[data-que-count-id="${questionId}"]`);
		const categoryElement = document.querySelector(`li[data-category="${category}"]`) as HTMLElement | null;

		if (countBoxElement) {
			if (active) {
				countBoxElement.classList.add("bg-primary-50", "text-primary-600");
			} else {
				countBoxElement.classList.remove("bg-primary-50", "text-primary-600");
			}
		}

		if (categoryElement) {
			// split returns [""] if used on a empty string "",
			// hence if only one question is visible the result will be data-que="|:questionId"
			// and data-que=null if no questions are visible
			let categoryActiveQuestions = (categoryElement.dataset.que ?? "").split("|");
			
			if (active) {
				categoryActiveQuestions.push(questionId)
				categoryElement.dataset.que = categoryActiveQuestions.join("|")
			} else {
				categoryElement.dataset.que = categoryActiveQuestions.filter(_ => _ !== questionId).join("|")
			}
		}

		const allCountBoxes = document.querySelectorAll(`span[data-que-count-id].bg-primary-50`);
		allCountBoxes.forEach((_) => _.classList.remove("rounded-t-lg", "rounded-b-lg"));

		const allActiveCountBoxes = document.querySelectorAll(`span[data-que-count-id].bg-primary-50`);
		if (allActiveCountBoxes.length > 1) {
			allActiveCountBoxes[0].classList.add("rounded-t-lg");
			allActiveCountBoxes[allActiveCountBoxes.length - 1].classList.add("rounded-b-lg");
		} else {
			allActiveCountBoxes[0]?.classList.add("rounded-t-lg", "rounded-b-lg");
		}

		// TODO: uncomment the below line for countBox scrolling
		// allActiveCountBoxes[0]?.scrollIntoView({ behavior: "smooth", block: "center" });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedEntry, ...dependencies]);

	return {
		ref
	};
};

export default useActiveQuestionCount;

