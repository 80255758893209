import { CalendarBlank, List, UserPlus } from "@phosphor-icons/react";
import { Button } from "antd";
import logo from "asset/logo.svg";
import {Flag} from "x-wings";
import { useApp } from "context/AppContext";
import dayjs from "dayjs";
import { Flags } from "flagpack-core";
import { useQuestionnaire } from "pages/ShareQuestionnaire";
import React from "react";

const HeaderTitle: React.FC = () => {
	const { order, coTravelers, applicationId, companyDetails } = useApp();
	const { setIsSidebarOpen, setIsCoTravelerModalOpen } = useQuestionnaire();
	const traveler = Object.values(coTravelers).find((t) => t.application_id === applicationId);

	return (
		<>
			{order && (
				<div className="hidden md:flex items-center gap-3">
					<Flag code={order.country.symbol as Flags} size="l" />
					<span className="text-lg text-gray-800 font-semibold">{order.country.name}</span>
					<span className="text-3xl text-gray-300 font-medium">·</span>
					<span className="flex items-center gap-1.5 text-lg text-gray-800 font-semibold">{`${traveler?.first_name} ${traveler?.last_name}`}</span>
					<span className="text-3xl text-gray-300 font-medium">·</span>
					<span className="flex items-center gap-1.5 text-base text-gray-500 font-medium">
						<CalendarBlank size={16} color="#667085" weight="bold" />
						{dayjs(order.travel_start_date).format("D MMM")} -
						{dayjs(order.travel_end_date).format(" D MMM")}
					</span>
				</div>
			)}
			<div className="flex md:!hidden items-center justify-between h-12">
				<Button type="text" style={{ padding: "0" }} onClick={() => setIsSidebarOpen(true)}>
					<List className="mx-1" size={28} color="#4B5563" />
				</Button>
				<img height={48} width={48} src={companyDetails?.organisation.agency_logo ?? logo} alt="logo" className="h-full w-auto mix-blend-multiply" />
				<Button type="text" style={{ padding: "0" }} onClick={() => setIsCoTravelerModalOpen(true)}>
					<UserPlus className="mx-1" size={28} color="#4B5563" />
				</Button>
			</div>
		</>
	);
};

export default HeaderTitle;

