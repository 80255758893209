import { Button, Form, Input } from "antd";
import FormItem from "antd/es/form/FormItem";
import { AxiosError } from "axios";
import { useApp } from "context/AppContext";
import { claimApplicationByApplicationId, searchPassportByOrderId } from "pages/SignUp/Api";
import { SyntheticEvent, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const PassportStep: React.FC = () => {
	const { coTravelers } = useApp();
	const form = Form.useFormInstance<SignupFormValues>();

	const navigate = useNavigate();
	const { orderId } = useParams();

	const searchCancelToken = useRef<AbortController>();
	const [applicationId, setApplicationId] = useState(Object.values(coTravelers)[0]?.application_id ?? "");
	const [isSearching, setIsSearching] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleSearchPassport = async (passport: string) => {
		if (!orderId) {
			navigate("/notfound");
			return;
		}
		searchCancelToken.current?.abort();
		searchCancelToken.current = new AbortController();
		try {
			setIsSearching(true);
			const { data } = await searchPassportByOrderId(orderId, passport, searchCancelToken.current.signal);
			
			setApplicationId(data.data.application_id);
		} catch (error) {
			if (error instanceof AxiosError) {
				console.warn("Cancelled", error);
			}
			throw error;
		} finally {
			setIsSearching(false);
		}
	};

	const handleClaimApplication = async () => {
		if (!orderId) {
			navigate("/notfound");
			return;
		}
		try {
			setIsSubmitting(true);
			await claimApplicationByApplicationId(applicationId, orderId);
			navigate(`/questionnaire/${orderId}?application_id=${applicationId}`);
		} catch (error) {
			console.error(error);
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<div className="flex flex-col items-stretch gap-4 p-6">
			<div className="hidden md:block border-b border-solid border-gray-200 mt-2" />
			<Form.Item
				name="passport"
				label="Passport number"
				getValueFromEvent={(event: SyntheticEvent<HTMLInputElement>) => {
					event.currentTarget.value = event.currentTarget.value.toUpperCase();
					return event.currentTarget.value;
				}}
				initialValue={Object.values(coTravelers)[0]?.passport}
				required
				rules={[
					{
						message: "Traveller does not exist in order",
						required: true,
						validator: (_, value) => handleSearchPassport(value),
						validateTrigger: ["onBlur", "onChange"]
					}
				]}>
				<Input className="text-uppercase" placeholder="X6044075" maxLength={8} />
			</Form.Item>
			<FormItem shouldUpdate>
				{() => (
					<Button
						block
						htmlType="submit"
						type="primary"
						loading={isSubmitting}
						disabled={isSearching || form.getFieldError("passport").length > 0 || !applicationId}
						onClick={handleClaimApplication}>
						Continue
					</Button>
				)}
			</FormItem>
		</div>
	);
};

export default PassportStep;

