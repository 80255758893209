import { Plus } from "@phosphor-icons/react";
import { Button, Modal } from "antd";
import clsx from "clsx";
import { useApp } from "context/AppContext";
import React, { useState } from "react";
import EmptyState from "./Components/EmptyState";
import AddCoTravelerModal from "./Components/Modal";
import "./styles.css";
import { useQuestionnaire } from "pages/ShareQuestionnaire";

const AddCoTraveller: React.FC = () => {
	const { applicationId, coTravelers, setApplicationId } = useApp();
	const { isCoTravelerModalOpen, setIsCoTravelerModalOpen } = useQuestionnaire();
	const [isOpen, setIsOpen] = useState(false);

	const handleModalClose = () => {
		setIsOpen(false);
	};

	const renderContent = () =>
		Object.keys(coTravelers).length < 2 ? (
			<div
				className={
					"relative self-start justify-self-end rounded-xl shadow conic-gradient " +
					"before:content-[''] before:absolute before:inset-[-1px] " +
					"before:z-[-1] before:rounded-[calc(0.75rem+1px)]"
				}>
				<div className="flex flex-col items-stretch bg-white overflow-hidden rounded-xl">
					<EmptyState />
					<Button
						className="flex items-center justify-center gap-2 m-4"
						style={{ paddingInline: "0.75rem" }}
						onClick={handleModalOpen}>
						<Plus size={16} weight="bold" />
						Add co-traveler
					</Button>
				</div>
			</div>
		) : (
			<div className="self-start justify-self-end">
				<div className="flex flex-col items-stretch border-l-2 border-solid border-primary-50">
					{Object.entries(coTravelers).map(([id, traveler]) => {
						const percent =
							(((traveler.groupAnswers?.ANSWERED ?? 0) + (traveler.groupAnswers?.APPROVED ?? 0)) /
								traveler.questionCount) *
							100;

						return (
							<div
								key={id}
								className={clsx(
									"flex items-stretch gap-3 pl-4 py-1.5 pr-1 text-base text-gray-400 cursor-pointer hover:bg-gray-50",
									applicationId === traveler.application_id &&
										"shadow-[-2px_0px_0px_theme(colors.primary.600)] text-gray-800 cursor-default"
								)}
								onClick={() => {
									setApplicationId(traveler.application_id);
								}}>
								<span className="flex-1 text-ellipsis overflow-hidden whitespace-nowrap">{`${
									traveler.first_name
								} ${traveler.last_name ?? ""}`}</span>
								<span
									className={clsx(
										"pl-2.5 pr-2 rounded-full border border-solid border-amber-100 text-xs leading-6 text-amber-600",
										percent > 50 && "border-emerald-100 text-emerald-600"
									)}>
									{percent.toFixed(1)}%
								</span>
							</div>
						);
					})}
				</div>
				<Button
					className="flex items-center justify-center gap-2 mt-6"
					block
					style={{ paddingInline: "0.75rem" }}
					onClick={handleModalOpen}>
					<Plus size={16} weight="bold" />
					Add co-traveler
				</Button>
			</div>
		);

	const handleModalOpen = () => {
		setIsCoTravelerModalOpen(false);
		setIsOpen(true);
	};
	return (
		<>
			{renderContent()}
			<Modal
				open={isCoTravelerModalOpen}
				onCancel={() => setIsCoTravelerModalOpen(false)}
				footer={false}
				destroyOnClose
				centered
				maskStyle={{
					backgroundColor: "unset",
					backgroundImage: "linear-gradient(180deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.50) 100%)"
				}}>
				{renderContent()}
			</Modal>
			<AddCoTravelerModal isOpen={isOpen} onClose={handleModalClose} />
		</>
	);
};

export default AddCoTraveller;

