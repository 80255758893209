import { IconProps, FileArrowUp, ListChecks } from "@phosphor-icons/react";
import clsx from "clsx";
import { useSearchParams } from "react-router-dom";
import colors from "tailwindcss/colors";

const QuestionnaireCompleted: React.FC<{ className?: string }> = ({ className }) => {
	const [searchParams] = useSearchParams();
	const isDocumentPage = searchParams.get("page") === "documents";

	const iconProps: IconProps = {
		size: 40,
		color: colors.emerald["600"],
		className: "p-2 rounded-lg bg-gray-50 ring-1 ring-gray-900/10 shadow"
	};
	return (
		<div className={clsx("p-4 rounded-xl ring-1 ring-gray-900/10 bg-emerald-50 shadow-xl", className)}>
			{isDocumentPage ? <FileArrowUp {...iconProps} /> : <ListChecks {...iconProps} />}
			<h5 className="text-lg font-semibold text-emerald-600 mt-5">
				{isDocumentPage
					? "Your documents have been successfully uploaded!"
					: "Your questionnaire has been successfully answered!"}
			</h5>
			<p className="text-base text-gray-700 mt-1">We will keep you posted about the further process.</p>
		</div>
	);
};

export default QuestionnaireCompleted;
