import { Spin } from "antd";
import { useApp } from "context/AppContext";
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { generateQuestionnaire, getAnswerStatus } from "./Api";
import AddCoTraveller from "./Components/AddCoTraveller";
import CountBox from "./Components/CountBox";
import QuestionnaireForm from "./Components/Form";
import QuestionnaireCompleted from "./Components/Form/Components/QuestionnaireCompleted";
import Header from "./Components/Header";
import Sidebar from "./Components/Sidebar";
import useConditionalQuestionnaire from "./useConditionalQuestionnaire";

const QuestionnaireContext = createContext<QuestionnaireContextValues | undefined>(undefined);

const Questionnaire: React.FC = () => {
	const { getOrder, getCoTravelers, applicationId , coTravelers} = useApp();
	const [isLoading, setIsLoading] = useState(false);
	const [questionnaire, setQuestionnaire] = useState<GenerateQuestionnaire>();
	const [questions, setQuestions] = useState<Question[]>([]);
	const [answers, setAnswers] = useState<AnswerData[]>([]);
	const [categoryStatus, setCategoryStatus] = useState<Category>({});
	const [isFormCompleted, setIsFormCompleted] = useState<[boolean, boolean]>([false, false]); // [questionnaire, document]
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const [isCoTravelerModalOpen, setIsCoTravelerModalOpen] = useState(false);
	const navigate = useNavigate();
	const { orderId } = useParams();
	const [searchParams] = useSearchParams();
	const filteredQuestions = questions.filter((que) => {
		if (!que) return false;
		if (que.answer_source !== "USER_INPUT") return false;
		if (searchParams.get("page") === "documents") {
			return que.type === "DOCUMENT";
		} else {
			return que.type !== "DOCUMENT";
		}
	});

	const getAnswerByVisaQuestionId = (visaQuestionId: string) =>
		answers.find((_) => _.visa_questions_id === visaQuestionId);

	const updateAnswerByVisaQuestionId = (visaQuestionId: string, answer: AnswerData) => {
		setAnswers((prev) => {
			const index = prev.findIndex((_) => _.visa_questions_id === visaQuestionId);
			if (index === -1) return [...prev, answer];
			const _ = [...prev];
			_[index] = answer;
			return _;
		});
	};

	const { renderQuestionsOnAnswerChange } = useConditionalQuestionnaire({
		questionnaire,
		setQuestionnaire,
		getAnswerByVisaQuestionId
	});

	useEffect(() => {
		answers.forEach((ans) => {
			renderQuestionsOnAnswerChange(ans.visa_questions_id, ans.answer);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [answers]);

	useEffect(() => {
		if (!orderId) {
			navigate(`/notfound`);
			return;
		}
		getOrder(orderId);
		getCoTravelers(orderId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigate, orderId]);

	useEffect(() => {
		if (!orderId) {
			navigate(`/notfound`);
			return;
		}

		if (!applicationId) return;

		const fetch = async () => {
			try {
				setIsLoading(true);
				setQuestions([]);
				const promises = [generateQuestionnaire(orderId), getAnswerStatus(applicationId)] as const;

				const [questionnaireRes, answerStatusRes] = await Promise.all(promises);
				setQuestionnaire({
					questionsWithRank: questionnaireRes.data.data.questionsWithRank,
					questionMap: questionnaireRes.data.data.questionMap
				});
				setAnswers(answerStatusRes.data.data);
			} catch (error) {
			} finally {
				setIsLoading(false);
			}
		};

		fetch();
	}, [navigate, orderId, getOrder, applicationId]);


	useEffect(() => {

		if (isLoading) return;
		if(questionnaire === undefined) return;
		
		setQuestions(() => {
			const _ = questionnaire.questionsWithRank.reduce((prev, { id, rank }) => {
				const ques = questionnaire.questionMap[id];		
				if (ques && ques.is_root)
					prev.push({
						...ques,
						id,
						rank,
						answer_data: answers.find((_) => _.visa_questions_id === id)
					});
				return prev;
			}, [] as Question[]);
			return _;
		});


		setCategoryStatus(
			Object.entries(questionnaire.questionMap).reduce((prev, [id, curr]) => {
				if (prev[curr.category]) {
					prev[curr.category].totalCount++;
				} else {
					prev = {
						...prev,
						[curr.category]: {
							active: false,
							rejectedCount: 0,
							completedCount: 0,
							totalCount: 1
						}
					};
				}

				if (answers.find((_) => _.visa_questions_id === id)?.status === "ANSWERED") {
					prev[curr.category].completedCount++;
				} else if (answers.find((_) => _.visa_questions_id === id)?.status === "REJECTED") {
					prev[curr.category].rejectedCount++;
				}

				return prev;
			}, {} as Category)
		);
	}, [answers, isLoading, questionnaire]);


	return (
		<QuestionnaireContext.Provider
			value={{
				questions: filteredQuestions,
				category: categoryStatus,
				answers,
				isSidebarOpen,
				isCoTravelerModalOpen,
				isFormCompleted,
				setIsFormCompleted,
				setIsCoTravelerModalOpen,
				setIsSidebarOpen,
				setAnswers,
				setQuestions,
				setCategory: setCategoryStatus,
				updateAnswerByVisaQuestionId
			}}>
			<main className="relative grid grid-rows-[auto_1fr] md:grid-cols-[248px_1fr] min-h-screen">
				<Sidebar />
				<Header />
				{!isLoading && <section className="overflow-auto row-start-2 row-end-3 col-start-1 md:col-start-2 col-end-[-1] grid grid-cols-[auto_1fr] md:grid-cols-[auto_1fr_minmax(210px_,_0.4fr)] pt-8 md:px-8 px-4 pb-8 gap-4 md:gap-10  max-h-[calc(100svh-5.375rem)]">
					{!isLoading && questions.length === 0 && (
						<QuestionnaireCompleted className="col-start-1 col-end-3 self-start" />
					)}
					{questions.length > 0 && (
						<>
							<CountBox />
							<QuestionnaireForm />
						</>
					)}
					<div className="justify-self-end hidden md:block">
						<AddCoTraveller />
					</div>
				</section>}

				{isLoading && (
					<div className="absolute inset-0 row-start-1 col-start-1 row-end-[-1] col-end-[-1] backdrop-blur-sm bg-white/50 grid place-items-center z-50">
						<Spin size="large" />
					</div>
				)}
			</main>
		</QuestionnaireContext.Provider>
	);
};

export default Questionnaire;

export const useQuestionnaire = () => {
	const context = useContext(QuestionnaireContext);

	if (!context) {
		throw new Error("Cannot use useQuestionnaire outside the Provider");
	}

	return context;
};
