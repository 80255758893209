import clsx from "clsx";
import { useQuestionnaire } from "pages/ShareQuestionnaire";
import { useRef } from "react";

const CountBox: React.FC = () => {
	const {questions} = useQuestionnaire()
	const container = useRef(null);

	return (
		<div ref={container} className="scrollbar-hidden relative">
			<div className="question-count-container sticky top-0 z-10 flex flex-col place-self-start overflow-hidden">
				<span
					className={
						"sticky top-0 grid h-10 w-10 place-items-center rounded-lg text-base text-gray-500" +
						" border border-solid border-gray-300 bg-gray-50 font-medium shadow"
					}>
					Q
				</span>
				{questions.map(({ id }, index) => (
					<span
						key={id}
						data-que-count-id={id}
						className={clsx(
							"question-count grid h-10 w-10 cursor-pointer place-items-center text-base font-medium text-gray-500 transition-all duration-500"
						)}
						onClick={() => {
							const element = document.querySelector(`[data-question-id="${id}"]`);
							if (element) {
								element.scrollIntoView({ behavior: "smooth", block: "center" });
							}
						}}>
						{index + 1}
					</span>
				))}
			</div>
		</div>
	);
};

export default CountBox;



