import { Button, Form, Input, message } from "antd";
import { AxiosError } from "axios";
import { useApp } from "context/AppContext";
import useDelayedLoading from "hooks/useDelayedLoading";
import { guestSendOtp, guestLoginForOrderId } from "pages/SignUp/Api";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const getOtpPlaceholder = (len: number) => {
	let subArrLen = len % 2 === 0 ? len / 2 : len % 3 === 0 ? len / 3 : len;
	let arr: string[] = [];
	for (let d = 1; d <= len; d += subArrLen) {
		arr.push(Array(subArrLen).fill("x").join(""));
	}

	return arr.join(" ");
};

const OtpStep: React.FC = () => {
	const { getCoTravelers } = useApp();
	const form = Form.useFormInstance<SignupFormValues>();
	const otp = Form.useWatch("otp", form);
	const otp_digits = Form.useWatch("otp_digits", { form, preserve: true });
	const phone = Form.useWatch("phone", { form, preserve: true });
	const session_id = Form.useWatch("session_id", { form, preserve: true });

	const { orderId } = useParams();
	const navigate = useNavigate();

	const [isSendingOtp, setIsSendingOtp] = useDelayedLoading(false);
	const [isVerifyingOtp, setIsVerifyingOtp] = useState(false);

	const handleSendOtp = async () => {
		try {
			setIsSendingOtp(true);

			const { data } = await guestSendOtp(phone);

			form.setFieldValue("session_id", data.data.session_id);
			form.setFieldValue("otp_digits", data.data.digits);
			form.setFieldValue("otp", "");
			message.success(
				<span className="text-base text-gray-500">
					Code sent to <span className="font-medium text-primary-600">{phone}</span>
				</span>
			);
		} catch (err) {
			console.error(err);
		} finally {
			setIsSendingOtp(false);
		}
	};

	const handleVerifyOtp = async () => {
		if (!orderId) {
			navigate("/notfound");
			return;
		}
		try {
			setIsVerifyingOtp(true);
			// const verifyOtpRes = await verifyOtp(phone, session_id, otp);
			const { data } = await guestLoginForOrderId(phone, orderId, otp, session_id);
			localStorage.setItem("jwt-token", data.data.token);

			const travelers = await getCoTravelers(orderId);
			if (travelers && travelers.length > 0) {
				message.success(`Welcome ${travelers[0].first_name}`);
			}
			form.setFieldValue("step", "passport");
		} catch (error) {
			if (error instanceof AxiosError) {
				console.log(error);

				if (error.response?.status === 401) {
					message.error("OTP does not match");
				}
			}
			console.error(error);
		} finally {
			setIsVerifyingOtp(false);
		}
	};

	return (
		<div className="flex flex-col items-stretch gap-4 p-6">
			<div className="hidden md:block border-b border-solid border-gray-200 mt-2" />
			<Form.Item name="otp" label="OTP" required>
				<Input
					type="number"
					onInput={(event) => {
						if (event.currentTarget.value.length > event.currentTarget.maxLength)
							event.currentTarget.value = event.currentTarget.value.slice(
								0,
								event.currentTarget.maxLength
							);
					}}
					inputMode="numeric"
					maxLength={otp_digits}
					placeholder={getOtpPlaceholder(otp_digits)}
				/>
			</Form.Item>
			<div className="flex gap-3 items-stretch justify-stretch">
				<Button className="flex-1" loading={isSendingOtp} onClick={handleSendOtp}>
					Re-send
				</Button>
				<Button
					htmlType="submit"
					className="flex-1"
					type="primary"
					disabled={!otp || otp?.length < otp_digits}
					loading={isVerifyingOtp}
					onClick={handleVerifyOtp}>
					Verify OTP
				</Button>
			</div>
		</div>
	);
};

export default OtpStep;

