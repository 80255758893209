import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 440 150" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g clipPath="url(#clip0_6411_5966)">
			<rect width={440} height={150} fill="white" />
			<mask
				id="mask0_6411_5966"
				style={{
					maskType: "alpha"
				}}
				maskUnits="userSpaceOnUse"
				x={-1}
				y={-120}
				width={442}
				height={442}>
				<circle cx={220} cy={101} r={221} fill="url(#paint0_radial_6411_5966)" />
			</mask>
			<g mask="url(#mask0_6411_5966)">
				<rect x={14.5} y={-40.5} width={411} height={267} rx={47.5} stroke="#E5E7EB" />
				<rect x={40.5} y={-32.5} width={359} height={267} rx={47.5} stroke="#E5E7EB" />
				<rect x={64.5} y={-16.5} width={311} height={235} rx={39.5} stroke="#E5E7EB" />
				<rect x={85.5} y={-1.5} width={269} height={203} rx={31.5} stroke="#E5E7EB" />
				<rect x={104.5} y={15.5} width={231} height={171} rx={23.5} stroke="#E5E7EB" />
				<rect x={120.5} y={31.5} width={199} height={139} rx={15.5} stroke="#E5E7EB" />
			</g>
			<g filter="url(#filter0_f_6411_5966)">
				<ellipse cx={219.5} cy={-126.5} rx={220.5} ry={62.5} fill="#A5B4FC" />
			</g>
			<g filter="url(#filter1_ddi_6411_5966)">
				<rect x={120} y={29} width={200} height={140} rx={16} fill="#E0E7FF" />
				<g filter="url(#filter2_dd_6411_5966)">
					<rect x={136} y={45} width={38} height={40} rx={8} fill="white" />
				</g>
				<g filter="url(#filter3_dd_6411_5966)">
					<rect
						x={136}
						y={107}
						width={80}
						height={10}
						rx={4}
						fill="white"
						fillOpacity={0.6}
						shapeRendering="crispEdges"
					/>
				</g>
				<g filter="url(#filter4_dd_6411_5966)">
					<rect
						x={136}
						y={123}
						width={57}
						height={10}
						rx={4}
						fill="white"
						fillOpacity={0.6}
						shapeRendering="crispEdges"
					/>
				</g>
				<g filter="url(#filter5_dd_6411_5966)">
					<rect
						x={222}
						y={107}
						width={30}
						height={10}
						rx={4}
						fill="white"
						fillOpacity={0.6}
						shapeRendering="crispEdges"
					/>
				</g>
				<g filter="url(#filter6_dd_6411_5966)">
					<rect
						x={199}
						y={123}
						width={38}
						height={10}
						rx={4}
						fill="white"
						fillOpacity={0.6}
						shapeRendering="crispEdges"
					/>
				</g>
				<rect x={120.5} y={29.5} width={199} height={139} rx={15.5} stroke="url(#paint1_linear_6411_5966)" />
			</g>
			<rect y={79} width={440} height={71} fill="url(#paint2_linear_6411_5966)" />
			<g filter="url(#filter7_dddd_6411_5966)">
				<rect x={268} y={49} width={71} height={22} rx={6} fill="#4F46E5" />
				<g filter="url(#filter8_dd_6411_5966)">
					<text
						fill="#fff"
						xmlSpace="preserve"
						style={{
							whiteSpace: "pre"
						}}
						fontSize={10}
						fontWeight={500}
						letterSpacing="0.02em">
						<tspan x={278} y={63.6364}>
							{"X987654_"}
						</tspan>
					</text>
				</g>
			</g>
		</g>
		<defs>
			<filter
				id="filter0_f_6411_5966"
				x={-151}
				y={-339}
				width={741}
				height={425}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB">
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
				<feGaussianBlur stdDeviation={75} result="effect1_foregroundBlur_6411_5966" />
			</filter>
			<filter
				id="filter1_ddi_6411_5966"
				x={98}
				y={27}
				width={244}
				height={184}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB">
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feMorphology radius={3} operator="erode" in="SourceAlpha" result="effect1_dropShadow_6411_5966" />
				<feOffset dy={10} />
				<feGaussianBlur stdDeviation={5} />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6411_5966" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feMorphology radius={3} operator="erode" in="SourceAlpha" result="effect2_dropShadow_6411_5966" />
				<feOffset dy={20} />
				<feGaussianBlur stdDeviation={12.5} />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
				<feBlend mode="normal" in2="effect1_dropShadow_6411_5966" result="effect2_dropShadow_6411_5966" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_6411_5966" result="shape" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feMorphology radius={2.5} operator="erode" in="SourceAlpha" result="effect3_innerShadow_6411_5966" />
				<feOffset />
				<feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
				<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
				<feBlend mode="normal" in2="shape" result="effect3_innerShadow_6411_5966" />
			</filter>
			<filter
				id="filter2_dd_6411_5966"
				x={128}
				y={39}
				width={54}
				height={56}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB">
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={1} />
				<feGaussianBlur stdDeviation={0.5} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6411_5966" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={4} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
				<feBlend mode="normal" in2="effect1_dropShadow_6411_5966" result="effect2_dropShadow_6411_5966" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_6411_5966" result="shape" />
			</filter>
			<filter
				id="filter3_dd_6411_5966"
				x={128}
				y={101}
				width={96}
				height={26}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB">
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={1} />
				<feGaussianBlur stdDeviation={0.5} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6411_5966" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={4} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
				<feBlend mode="normal" in2="effect1_dropShadow_6411_5966" result="effect2_dropShadow_6411_5966" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_6411_5966" result="shape" />
			</filter>
			<filter
				id="filter4_dd_6411_5966"
				x={128}
				y={117}
				width={73}
				height={26}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB">
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={1} />
				<feGaussianBlur stdDeviation={0.5} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6411_5966" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={4} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
				<feBlend mode="normal" in2="effect1_dropShadow_6411_5966" result="effect2_dropShadow_6411_5966" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_6411_5966" result="shape" />
			</filter>
			<filter
				id="filter5_dd_6411_5966"
				x={214}
				y={101}
				width={46}
				height={26}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB">
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={1} />
				<feGaussianBlur stdDeviation={0.5} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6411_5966" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={4} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
				<feBlend mode="normal" in2="effect1_dropShadow_6411_5966" result="effect2_dropShadow_6411_5966" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_6411_5966" result="shape" />
			</filter>
			<filter
				id="filter6_dd_6411_5966"
				x={191}
				y={117}
				width={54}
				height={26}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB">
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={1} />
				<feGaussianBlur stdDeviation={0.5} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6411_5966" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={4} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
				<feBlend mode="normal" in2="effect1_dropShadow_6411_5966" result="effect2_dropShadow_6411_5966" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_6411_5966" result="shape" />
			</filter>
			<filter
				id="filter7_dddd_6411_5966"
				x={244}
				y={29}
				width={119}
				height={70}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB">
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feMorphology radius={1.5} operator="dilate" in="SourceAlpha" result="effect1_dropShadow_6411_5966" />
				<feOffset />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6411_5966" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={1} />
				<feGaussianBlur stdDeviation={0.5} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
				<feBlend mode="normal" in2="effect1_dropShadow_6411_5966" result="effect2_dropShadow_6411_5966" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={4} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
				<feBlend mode="normal" in2="effect2_dropShadow_6411_5966" result="effect3_dropShadow_6411_5966" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={4} />
				<feGaussianBlur stdDeviation={12} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
				<feBlend mode="normal" in2="effect3_dropShadow_6411_5966" result="effect4_dropShadow_6411_5966" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect4_dropShadow_6411_5966" result="shape" />
			</filter>
			<filter
				id="filter8_dd_6411_5966"
				x={277.277}
				y={54.6211}
				width={51.8047}
				height={12.2812}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB">
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={1} />
				<feGaussianBlur stdDeviation={0.5} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6411_5966" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={-1} />
				<feGaussianBlur stdDeviation={0.5} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.12 0" />
				<feBlend mode="normal" in2="effect1_dropShadow_6411_5966" result="effect2_dropShadow_6411_5966" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_6411_5966" result="shape" />
			</filter>
			<radialGradient
				id="paint0_radial_6411_5966"
				cx={0}
				cy={0}
				r={1}
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(220 101) rotate(90) scale(221 341.647)">
				<stop stopColor="white" />
				<stop offset={1} stopColor="white" stopOpacity={0} />
			</radialGradient>
			<linearGradient
				id="paint1_linear_6411_5966"
				x1={220}
				y1={29}
				x2={220}
				y2={169}
				gradientUnits="userSpaceOnUse">
				<stop stopColor="#E0E7FF" />
				<stop offset={1} stopColor="#F3F4F6" stopOpacity={0} />
			</linearGradient>
			<linearGradient
				id="paint2_linear_6411_5966"
				x1={220}
				y1={79}
				x2={220}
				y2={150}
				gradientUnits="userSpaceOnUse">
				<stop stopColor="white" stopOpacity={0} />
				<stop offset={1} stopColor="white" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_6411_5966"
				x1={303.5}
				y1={52}
				x2={303.5}
				y2={68}
				gradientUnits="userSpaceOnUse">
				<stop offset={0.399967} stopColor="white" />
				<stop offset={1} stopColor="#CCCCCC" />
			</linearGradient>
			<clipPath id="clip0_6411_5966">
				<rect width={440} height={150} fill="white" />
			</clipPath>
		</defs>
	</svg>
);
export default SvgComponent;


