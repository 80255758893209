import logo from "asset/logo.svg";
import { useApp } from "context/AppContext";

const Header: React.FC = () => {
	const { companyDetails } = useApp();
	return (
		<header
			className={
				"h-14 md:h-20 bg-white " +
				"flex justify-center items-center gap-3 z-10" +
				" border-b border-solid border-gray-200"
			}>
				<img
					className="flex h-full w-auto max-h-[80%] justify-center mix-blend-multiply"
					src={companyDetails?.organisation.agency_logo ?? logo}
					height={63}
					width={150}
					alt={companyDetails?.organisation.name}
				/>
		</header>
	);
};

export default Header;

