import { CheckCircle, Eye, SealCheck, Warning, XCircle } from "@phosphor-icons/react";
import { DatePicker, Form, FormItemProps, Input, Radio, Select, Space, Spin, Tooltip, Upload } from "antd";
import { ReactComponent as DocumentIcon } from "asset/ShareQuestionnaire/document.svg";
import { AxiosError } from "axios";
import Overlay from "components/Overlay";
import { useApp } from "context/AppContext";
import { useQuestionnaire } from "pages/ShareQuestionnaire";
import { postAnswer } from "pages/ShareQuestionnaire/Api";
import useActiveQuestion from "pages/ShareQuestionnaire/useActiveQuestion";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Button, useBoolean } from "x-wings";
import "./styles.scss";
import dayjs from "dayjs";


type QuestionnaireFormItemProps = Question & {
	index: number;
};

const QuestionnaireFormItem: React.FC<QuestionnaireFormItemProps> = (props) => {
	const { applicationId } = useApp();
	const {  setCategory, updateAnswerByVisaQuestionId } = useQuestionnaire();
	const { orderId } = useParams();
	const [isPosting, setIsPosting] = useState(false);
	const [validateStatus, setValidateStatus] = useState<FormItemProps["validateStatus"]>();
	const [postingProgress, setPostingProgress] = useState(0);
	const [file, setFile] = useState<File>();
	const [searchParams] = useSearchParams();

	const status = props.answer_data?.status;

	const { ref } = useActiveQuestion({ dependencies: [searchParams] });

	const { value: isPreviewOpen, setTrue: onPreviewOpen, setFalse: onPreviewClose } = useBoolean(false);

	useEffect(() => {
		if (status === "REJECTED") {
			setValidateStatus("error");
		}
	}, [status]);

	const handlePostAnswer = async (
		answer: string,
		file?: File | Blob | string,
		onProgress?: ((event: UploadProgressEvent) => void) | undefined
	) => {
		if (!(answer || file)) return;
		if (!orderId || !applicationId) return;
		try {
			setIsPosting(true);
			setValidateStatus(undefined);
			const { data } = await postAnswer(
				orderId,
				applicationId,
				{
					visa_questions_id: props.id,
					traveller_id: applicationId,
					answer: answer,
					file: file
				},
				onProgress
			);
			setValidateStatus("success");
			updateAnswerByVisaQuestionId(props.id, data.data);
		} catch (error) {
			setValidateStatus("error");
			console.error(error);
		} finally {
			setIsPosting(false);
		}
	};

	const handleShowDocumentPreview: React.MouseEventHandler<HTMLElement> = (event) => {
		event.stopPropagation();
		onPreviewOpen();
	};

	return (
		<div className="relative" ref={ref} data-question-no={props.index} data-question-id={props.id} data-category={props.category}>
			{status === "APPROVED" && (
				<Tooltip
					title="The answer of the question is approved by the visa expert. You can't change the answer."
					className="absolute right-1 top-1 z-10 text-emerald-600">
					<SealCheck size={16} weight="fill" />
				</Tooltip>
			)}
			{status === "REJECTED" && (
				<Tooltip
					title="The answer of the question is rejected by the visa expert. Please resolve the issue with answer."
					className="absolute right-1 top-1 z-10 text-rose-600">
					<XCircle size={16} weight="fill" />
				</Tooltip>
			)}
			<Form.Item
				className="mb-6"
				colon={false}
				name={props.id}
				label={<div className="w-full flex items-center justify-between font-medium">{props.question}</div>}
				preserve
				required={props.is_required}
				help={status === "REJECTED" && props.answer_data?.issue_reason}
				validateStatus={validateStatus}>
				{props.type === "TEXTAREA" && (
					<Input.TextArea
						disabled={status === "APPROVED"}
						placeholder="Type here..."
						onBlur={(event) => handlePostAnswer(event.currentTarget.value)}
						defaultValue={props.answer_data?.answer[0]}
						onFocus={() => {
							setCategory((prev) => {
								const t = Object.entries(prev).reduce((prev, [cat, val]) => {
									val.active = cat === props.category;

									return { ...prev, [cat]: val };
								}, {});
								return t;
							});
						}}
					/>
				)}
				{props.type === "TEXT" && (
					<Input
						disabled={status === "APPROVED"}
						placeholder="Enter here"
						defaultValue={props.answer_data?.answer[0]}
						onBlur={(event) => handlePostAnswer(event.currentTarget.value)}
						onFocus={() => {
							setCategory((prev) =>
								Object.entries(prev).reduce((prev, [cat, val]) => {
									val.active = cat === props.category;

									return { ...prev, [cat]: val };
								}, {})
							);
						}}
					/>
				)}
				{props.type === "CALENDAR" && (
					<DatePicker
						disabled={status === "APPROVED"}
						className="w-full"
						size="large"
						defaultValue={dayjs(props.answer_data?.answer[0])}
						onSelect={(date) => handlePostAnswer(date.format("YYYY-MM-DD"))}
						onFocus={() => {
							setCategory((prev) =>
								Object.entries(prev).reduce((prev, [cat, val]) => {
									val.active = cat === props.category;

									return { ...prev, [cat]: val };
								}, {})
							);
						}}
					/>
				)}
				{props.type === "SINGLE_CHOICE" && props.options.length <= 10 && (
					<Radio.Group
						disabled={status === "APPROVED"}
						defaultValue={props.answer_data?.answer[0]}
						onChange={(event) => handlePostAnswer(event.target.value)}
						onFocus={() => {
							setCategory((prev) =>
								Object.entries(prev).reduce((prev, [cat, val]) => {
									val.active = cat === props.category;

									return { ...prev, [cat]: val };
								}, {})
							);
						}}>
						<Space direction="vertical">
							{props.options.map((val, index) => {
								return (
									<Radio key={index} value={val}>
										{val}
									</Radio>
								);
							})}
						</Space>
					</Radio.Group>
				)}
				{(props.type === "DROPDOWN" || (props.type === "SINGLE_CHOICE" && props.options.length > 10)) && (
					<Select
						disabled={status === "APPROVED"}
						defaultValue={props.answer_data?.answer[0]}
						placeholder="Select option"
						options={props.options.map((val) => ({ label: val, value: val }))}
						size="large"
						showSearch
						onChange={(value) => handlePostAnswer(value)}
						onFocus={() => {
							setCategory((prev) =>
								Object.entries(prev).reduce((prev, [cat, val]) => {
									val.active = cat === props.category;

									return { ...prev, [cat]: val };
								}, {})
							);
						}}
					/>
				)}
				{props.type === "DOCUMENT" && (
					<>
						<Upload.Dragger
							disabled={status === "APPROVED"}
							className="block"
							showUploadList={false}
							maxCount={1}
							beforeUpload={(file) => setFile(file)}
							customRequest={async ({ file, onProgress, onError, onSuccess }) => {
								const handleOnProgress: typeof onProgress = (event) => {
									setPostingProgress(event.percent ?? 0);
									onProgress?.(event);
								};
								try {
									await handlePostAnswer("", file, handleOnProgress);
									onSuccess?.("Uploaded");
								} catch (error) {
									if (error instanceof AxiosError) {
										onError?.({
											status: parseInt(error.code ?? "422"),
											method: "POST",
											url: error.config?.url ?? "localhost",
											...error
										});
									}
								}
							}}
							onDrop={() => {
								setCategory((prev) =>
									Object.entries(prev).reduce((prev, [cat, val]) => {
										val.active = cat === props.category;

										return { ...prev, [cat]: val };
									}, {})
								);
							}}>
							<div
								className="questionnaire-form-item-upload-dragger"
								data-uploading={isPosting}
								data-progress={postingProgress.toFixed(0)}>
								{file && (
									<div className="grid min-h-[148px]">
										<object
											className="w-full"
											data={!isPosting ? URL.createObjectURL(file) : undefined}
											type={file.type}>
											{file.name}
										</object>
										{isPosting && <div className="skeleton"></div>}

										<p className="-mb-2 mt-2 flex items-end gap-2 self-end text-base text-gray-700">
											{file.name}
										</p>
										{isPosting && <Spin className="absolute right-2 top-2" size="small" />}
										{validateStatus === "error" && (
											<Warning className="absolute right-2 top-2" size={20} color="#e11d48" />
										)}
										{validateStatus === "success" && (
											<CheckCircle className="absolute right-2 top-2" size={20} color="#059669" />
										)}
									</div>
								)}
								{!file && !props.answer_data && (
									<>
										<DocumentIcon className="mx-auto mb-6" />
										<p className="text-base text-gray-400">
											{isPosting ? "Uploading..." : "Upload document"}
										</p>
									</>
								)}

								{!file && props.answer_data && props.answer_data.assets_id.length > 0 && (
									<>
										<div className="flex flex-col items-center">
											<SealCheck className="mb-4" size={32} color="#16a34a" weight="duotone" />
											<h5 className="mb-3 text-lg font-semibold text-gray-900">Submitted</h5>
											<p className="flex items-center gap-2 text-sm text-gray-400">
												{props.answer_data.assets_id[0].doc_id}
											</p>
											<Button
												className="-mb-4 mt-4"
												type="link"
												onClick={handleShowDocumentPreview}>
												Preview
												<Eye className="ms-2" size={20} />
											</Button>
										</div>
										<Overlay open={isPreviewOpen} onClose={onPreviewClose}>
											Hello
										</Overlay>
									</>
								)}
							</div>
						</Upload.Dragger>
					</>
				)}
			</Form.Item>
		</div>
	);
};

export default QuestionnaireFormItem;

