import React from "react";
import HeaderTabs from "./Components/HeaderTabs";
import HeaderTitle from "./Components/HeaderTitle";

const Header: React.FC = () => {
	return (
		<div className="inline-flex flex-col gap-5 p-5 bg-white border-b border-solid border-gray-200 sticky -top-14 z-[5]">
			<HeaderTitle />
			<HeaderTabs className="sticky top-8" />
		</div>
	);
};

export default Header;

