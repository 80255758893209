import { Form } from "antd";
import { useQuestionnaire } from "../..";
import QuestionnaireFormItem from "./Components/QuestionnaireFormItem";
import QuestionnaireSubmit from "./Components/QuestionnaireSubmit";

const QuestionnaireForm: React.FC = () => {
	const { questions } = useQuestionnaire();
	const [form] = Form.useForm();

	const handleSubmitButtonState = () => {
		form.setFieldValue(
			"disable-submit-continue-button",
			!form.isFieldsTouched(
				questions.filter((que) => que.is_required).map((que) => que.id),
				true
			) ||
				form.getFieldsError().filter(({ errors }) => {
					return errors.length;
				}).length > 0
		);
	};

	return (
		<Form
			form={form}
			layout="vertical"
			initialValues={{ "disable-submit-continue-button": true }}
			onFieldsChange={handleSubmitButtonState}
			className="scrollbar-hidden flex w-full flex-col items-stretch gap-10 md:pr-6">
			{questions.map(({ id, ...que }, index) => {
				if (!que.is_root) return null;
				return <QuestionnaireFormItem key={id} {...que} id={id} index={index} />;
			})}
			{questions.length > 0 && <QuestionnaireSubmit />}
		</Form>
	);
};

export default QuestionnaireForm;

